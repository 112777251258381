import { FC } from 'react';

interface Props {
  className?: string;
  type?: 'horizontal' | 'vertical';
}

const Divider: FC<Props> = (props = { type: 'vertical' }) => (
  <div
    className={`${
      props.type === 'horizontal' ? 'w-px' : 'h-px'
    } bg-gray-200 ${props.className}`}
  />
);
export default Divider;

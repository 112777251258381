import Lottie from 'lottie-react';
import { FC } from 'react';
import primary from '../../../animations/3dotsPrimary.json';
import white from '../../../animations/threeDots.json';

interface Props {
  palette?: 'primary' | 'white';
  className?: string;
}

export const Loader: FC<Props> = ({ palette, className }) => {
  if (palette === 'white') {
    return <Lottie className={className} animationData={white} autoPlay loop />;
  }

  return <Lottie className={className} animationData={primary} autoPlay loop />;
};

import { PropsWithChildren } from 'react';
import { Close as IconClose } from '../icons';
import { useToggle } from '../../hooks';

interface Props extends PropsWithChildren {
  className?: string;
  dismissable?: boolean;
  status?: 'error' | 'info' | 'success' | 'warning';
}

export default function Alert({
  children,
  className,
  dismissable,
  status = 'info',
}: Props) {
  const {isOpen, close} = useToggle(true);

  function getColors() {
    if (status === 'success') {
      return 'bg-success-light text-greyscale-body';
    }
    if (status === 'error') {
      return 'bg-error text-greyscale-offwhite';
    }
    if (status === 'warning') {
      return 'bg-warning-light text-warning-dark';
    }
    return 'bg-greyscale-input text-greyscale-label';
  }

  if (dismissable && !isOpen) {
    return null;
  }

  return (
    <div className={`flex items-start justify-between text-sm font-medium tracking-tight px-6 py-4 rounded-lg ${getColors()} ${className}`}>
      <span>{children}</span>
      {dismissable && (
        <button
          className="shrink-0 inline-block"
          onClick={close}
          type="button"
        >
          <IconClose className="h-5" />
        </button>
      )}
    </div>
  );
}

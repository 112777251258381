import { ChangeEvent, FC } from 'react';

interface Props<InputT> {
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string | React.ReactNode;
  name?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: 'sm' | 'md' | 'lg';
  value?: InputT;
  checked: boolean;
  reverse?: boolean;
  id?: string;
}

const Checkbox: FC<Props<any>> = (props) => {
  return (
    <div className={props.className}>
      <label
        className={`flex items-start m-0 gap-2 cursor-pointer ${
          props.reverse ? 'flex-row-reverse' : 'flex-row'
        }`}
      >
        <input
          id={props.id}
          checked={props.checked}
          className={[
            'mt-1 form-checkbox rounded border-gray-300 text-primary shadow-sm focus:border-gray-300 focus:ring focus:ring-offset-0 focus:ring-gray-200 focus:ring-opacity-50 disabled:text-indigo-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:shadow-none',
            props.size === 'lg'
              ? 'h-6 w-6'
              : props.size === 'md'
              ? 'h-5 w-5'
              : 'h-4 w-4',
          ].join(' ')}
          disabled={props.disabled}
          name={props.name}
          type="checkbox"
          value={props.value}
          onChange={props.onChange}
        />
        {!!props.label && (
          <span
            className={[
              props.labelClassName || {},
              'flex-1 text-gray-700 text-base',
            ].join(' ')}
          >
            {props.label}
          </span>
        )}
      </label>
      {!!props.errorMessage && (
        <span className="text-red-500 text-xs">{props.errorMessage}</span>
      )}
    </div>
  );
};

export default Checkbox;

import { MouseEvent, PropsWithChildren, forwardRef } from 'react';
import { Loader } from '@/components/design-system/loader/Loader';

interface Props extends PropsWithChildren {
  id?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  palette?: 'primary' | 'accent' | 'gray' | 'white';
  rounded?: boolean;
  theme?: 'primary' | 'outline' | 'ghost';
  type?: 'button' | 'link' | 'reset' | 'submit';
  size?: 'sm' | 'md' | 'lg';
  variant?: 'default' | 'block' | 'icon';
  target?: string;
  href?: string;
}

function Button(
  {
    id,
    disabled = false,
    loading = false,
    palette = 'primary',
    rounded = false,
    size = 'md',
    theme = 'primary',
    variant = 'default',
    ...props
  }: Props,
  ref,
) {
  const getColors = () => {
    if (palette === 'primary') {
      return theme === 'primary'
        ? 'bg-gradient-to-r from-primary to-[#ef5989] text-white hover:text-white hover:from-primary-dark hover:to-primary-dark disabled:from-secondary-light disabled:to-secondary-light'
        : theme === 'outline'
        ? 'bg-transparent border border-primary text-primary hover:bg-primary hover:border-primary hover:text-white disabled:border-secondary-light disabled:text-secondary-light'
        : 'bg-transparent text-primary hover:bg-primary-dark hover:text-white disabled:text-secondary-light';
    }
    if (palette === 'gray') {
      return theme === 'primary'
        ? 'bg-gray-200 text-gray-500 hover:bg-gray-300 hover:text-gray-500'
        : theme === 'outline'
        ? 'bg-transparent border border-gray-600 text-gray-600 hover:bg-gray-300 hover:border-gray-300 hover:text-gray-600'
        : 'bg-transparent text-gray-600 hover:bg-gray-300 hover:text-gray-600';
    }
    if (palette === 'white') {
      return theme === 'primary'
        ? 'bg-white text-gray-500 hover:bg-gray-300'
        : theme === 'outline'
        ? 'bg-transparent border border-white text-white hover:bg-gray-300 hover:border-gray-300 hover:text-gray-600'
        : 'bg-transparent text-white hover:bg-gray-300 hover:text-gray-600';
    }
    if (palette === 'accent') {
      return theme === 'primary'
        ? 'bg-success text-white hover:bg-success-dark'
        : theme === 'outline'
        ? 'bg-transparent border border-accent text-accent hover:bg-gray-300 hover:border-accent-dark hover:text-accent-dark'
        : 'bg-transparent text-accent hover:bg-gray-300 hover:text-accent-dark';
    }
  };

  const getDimensions = () => {
    if (variant === 'icon') {
      return size === 'lg'
        ? 'w-12 h-12'
        : size === 'sm'
        ? 'w-8 h-8'
        : 'w-10 h-10';
    }
    const value =
      size === 'lg'
        ? 'btn-lg'
        : size === 'sm'
        ? 'btn-sm'
        : 'btn-md';
    return `${variant === 'block' ? 'w-full' : ''} ${value}`;
  };

  const getHeight = () => {
    return size === 'lg'
      ? 'h-12'
      : size === 'sm'
        ? 'h-8'
        : 'h-10';
  };

  const getClass = () => {
    return [
      'button group ',
      rounded ? '!rounded-full' : '',
      props.className,
      getColors(),
      getDimensions(),
    ].join(' ');
  };

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (loading || disabled) {
      return;
    }
    if (props.onClick) {
      props.onClick(e);
    }
  };

  const renderChildren = () => {
    return (
      <div className={'flex items-center justify-center gap-2 ' + getHeight()}>
        {props.children}
        {loading && (
          <Loader
            palette={theme === 'primary' ? 'white' : 'primary' }
            className={getHeight()}/>
        )}
      </div>
    );
  };

  if (props.type === 'link') {
    return (
      <div {...props} className={getClass()}>
        {renderChildren()}
      </div>
    );
  }

  return (
    <button
      id={id}
      className={getClass()}
      disabled={disabled}
      onClick={handleClick}
      type={props.type}
    >
      {renderChildren()}
    </button>
  );
}

export default forwardRef(Button);

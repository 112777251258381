'use client';

import { FC, ReactElement, useRef } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import type SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ChevronLeft, ChevronRight } from '../icons';
import { Button } from './index';

export interface Breakpoints {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
}

interface Props<ItemT> {
  breakpoints?: Breakpoints;
  columns?: number | 'auto';
  data: ReadonlyArray<ItemT>;
  keyExtractor?: ((item: ItemT, index: number) => string) | undefined;
  loading?: boolean;
  loadingItemsCount?: number;
  renderItem: (item: ItemT, index?: number) => ReactElement;
  renderLoadingItem?: (index?: number) => ReactElement;
  scrollable?: boolean;
  title?: string;
  hasNextPage?: boolean;
  onLoadMore?: () => void;
}

const GridList: FC<Props<any>> = (
  props = {
    columns: 1,
    loading: false,
    loadingItemsCount: 16,
    scrollable: false,
    data: [],
    renderItem: () => <></>,
  },
) => {
  const swiperRef = useRef<SwiperCore>();
  const [infiniteRef] = useInfiniteScroll({
    loading: props.loading!,
    disabled: !props.onLoadMore,
    hasNextPage: props.hasNextPage!,
    onLoadMore: () => {
      if (props.onLoadMore) {
        props.onLoadMore();
      }
    },
  });

  const getBreakpoints = () => {
    return {
      ...(props.breakpoints!.sm && {
        640: { slidesPerView: props.breakpoints!.sm },
      }),
      ...(props.breakpoints!.md && {
        768: { slidesPerView: props.breakpoints!.md },
      }),
      ...(props.breakpoints!.lg && {
        1024: { slidesPerView: props.breakpoints!.lg },
      }),
      ...(props.breakpoints!.xl && {
        1280: { slidesPerView: props.breakpoints!.xl },
      }),
      ...(props.breakpoints!.xxl && {
        1536: { slidesPerView: props.breakpoints!.xxl },
      }),
    };
  };

  const getClass = () => {
    return [
      'grid gap-8',
      props.columns ? `grid-cols-${props.columns}` : '',
      props.breakpoints!.sm ? `sm:grid-cols-${props.breakpoints!.sm}` : '',
      props.breakpoints!.md ? `md:grid-cols-${props.breakpoints!.md}` : '',
      props.breakpoints!.lg ? `lg:grid-cols-${props.breakpoints!.lg}` : '',
      props.breakpoints!.xl ? `xl:grid-cols-${props.breakpoints!.xl}` : '',
      props.breakpoints!.xxl
        ? `2xl:grid-cols-${props.breakpoints!.xxl}`
        : '',
    ].join(' ');
  };

  const renderItems = () => {
    if (props.scrollable) {
      return (
        <Swiper
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          slidesPerView={props.columns}
          spaceBetween={32}
          // @ts-ignore
          breakpoints={getBreakpoints()}
        >
          {props.data.map((item, index) => (
            <SwiperSlide
              key={props.keyExtractor && props.keyExtractor(item, index)}
            >
              {props.renderItem(item, index)}
            </SwiperSlide>
          ))}
        </Swiper>
      );
    }

    return (
      <div className={getClass()}>
        {props.data.map((item, index) => (
          <div key={props.keyExtractor && props.keyExtractor(item, index)}>
            {props.renderItem(item, index)}
          </div>
        ))}
        {Array(props.loading ? props.loadingItemsCount : 0)
          .fill(0)
          .map((_, i) => (
            <div key={i}>
              {props.renderLoadingItem && props.renderLoadingItem(i)}
            </div>
          ))}
        {props.hasNextPage && <div ref={infiniteRef} />}
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {(!!props.title || props.scrollable) && (
        <div className="flex items-center">
          {!!props.title && (
            <h2 className="text-3xl md:text-4xl font-bold tracking-tighter mb-0 text-greyscale-title">
              {props.title}
            </h2>
          )}
          <div className="flex-1" />
          {props.scrollable && (
            <div className="flex items-center shrink-0">
              <Button
                theme="ghost"
                palette="gray"
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <ChevronLeft className="h-5 -mx-1" />
              </Button>
              <Button
                theme="ghost"
                palette="gray"
                onClick={() => swiperRef.current?.slideNext()}
              >
                <ChevronRight className="h-5 -mx-1" />
              </Button>
            </div>
          )}
        </div>
      )}
      {renderItems()}
    </div>
  );
};

export default GridList;

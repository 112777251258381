'use client';

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
} from 'chart.js';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Input } from './index';

interface Props {
  className?: string;
  histogram?: boolean;
  max?: number;
  min?: number;
  onChange?: (value: number[] | undefined) => void;
  steps?: number[];
  value?: number[];
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler);

export default function Range(props: Props) {
  const computedValue = useMemo<number[] | undefined>(() => {
    if (!props.steps) {
      return props.value;
    }
    return [
      props.steps.indexOf(props.value![0]),
      props.steps.indexOf(props.value![1] ?? props.value![0]),
    ];
  }, [props.value, props.steps]);

  const [min, max] = useMemo(() => {
    return [
      props.steps && props.steps.length > 1 ? props.steps[0] : props.min,
      props.steps && props.steps.length > 1
        ? props.steps.length - 1
        : props.max,
    ];
  }, [props.max, props.min, props.steps]);

  const handleChange = (value?: number[]) => {
    if (!props.steps && props.onChange) {
      return props.onChange(value);
    }
    props.onChange &&
      props.onChange([props.steps![value![0]], props.steps![value![1]]]);
  };

  return (
    <div className={props.className}>
      {props.histogram && (
        <Line
          datasetIdKey="id"
          options={{
            scales: {
              y: {
                display: false,
                beginAtZero: true,
              },
              x: {
                display: false,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
          }}
          data={{
            labels: props.steps,
            datasets: [
              {
                fill: true,
                data: [2, 2, 3, 4, 3],
                pointStyle: 'line',
                tension: 0.3,
                segment: {
                  borderColor: (ctx) => {
                    return ctx.p0DataIndex < computedValue![0] ||
                      ctx.p1DataIndex > computedValue![1]
                      ? 'rgb(209, 213, 219)'
                      : 'rgb(207, 28, 68)';
                  },
                  backgroundColor: (ctx) => {
                    return ctx.p0DataIndex < computedValue![0] ||
                      ctx.p1DataIndex > computedValue![1]
                      ? 'rgba(209, 213, 219, 0.2)'
                      : 'rgba(207, 28, 68, 0.2)';
                  },
                },
              },
            ],
          }}
        />
      )}
      <Slider
        className="-mt-3"
        min={min}
        max={max}
        value={computedValue}
        range
        // @ts-ignore
        onChange={handleChange}
      />

      <div className="flex items-center gap-2 mt-2">
        <Input
          className="flex-1"
          disabled={!!props.steps}
          placeholder="Prezzo minimo"
          type="number"
          min={min}
          max={max}
          value={props.value && props.value[0] ? props.value[0].toString() : ''}
          onChange={(e) => {
            const v = parseInt(e.target.value) || 0;
            handleChange(
              v > props.value![1] ? [props.value![1], v] : [v, props.value![1]],
            );
          }}
        />
        <span>-</span>
        <Input
          className="flex-1"
          disabled={!!props.steps}
          placeholder="Prezzo massimo"
          type="number"
          min={min}
          max={max}
          value={props.value && props.value[1] ? props.value[1].toString() : ''}
          onChange={(e) => {
            const v = parseInt(e.target.value) || 0;
            handleChange(
              v < props.value![0] ? [v, props.value![0]] : [props.value![0], v],
            );
          }}
        />
      </div>
    </div>
  );
}
